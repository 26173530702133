import * as React from 'react';
import {BouncingLoader, Form, Modal, useExternalStore, UseModalReturn} from "@ova-studio/react-hyper-admin";
import {Media} from "../types/Media";
import {useMediaLibraryService} from "../services/MediaLibraryService";
import {FolderID} from "../types/MediaFolder";

export type MediaFormData = {
    id: Media['id'],
    folder_id: FolderID|null,
    meta_data: Record<string, string>
}

const prepareData = (data: MediaFormData) : Omit<MediaFormData, 'id'> => ({
    folder_id: data.folder_id,
    meta_data: data.meta_data,
})

const MediaEditFormBody = ({ data }: { data: MediaFormData }) => {

    const mediaManager = useMediaLibraryService().mediaManager;
    const folderManager = useMediaLibraryService().folderManager;
    const { folders } = useExternalStore(folderManager);

    const foldersOptions = React.useMemo(() => {
        return folders?.filter(f => f.parent !== null).map(f => ({value: f.id, label: f.name})) ?? [];
    }, [ folders ]);

    const handleSubmit = React.useCallback(async (payload: Record<string, any>) => {
        await mediaManager.handleEditFormSubmit(data.id, payload);
    }, [ data.id ]);

    return (
        <Form.Callback data={prepareData(data)} handleSubmit={handleSubmit} showSuccess={false}>
            <Modal.Body>
                <Form.Select
                    name='folder_id'
                    options={foldersOptions}
                    label='Папка'
                    allowEmpty={true}
                />

                <Form.Input
                    name='meta_data.name'
                    label='Назва'
                />

                <Form.Input
                    type='textarea'
                    name='meta_data.description'
                    label='Опис'
                    groupClass=''
                />
            </Modal.Body>
            <Modal.Footer>
                <Form.Submit>Зберегти</Form.Submit>
            </Modal.Footer>
        </Form.Callback>
    )
}

export type MediaModalState = UseModalReturn<MediaFormData>;

type MediaEditModalProps = {
    state: MediaModalState,
}

const MediaEditModal = ({ state }: MediaEditModalProps) => {
    return (
        <Modal state={state} title='Редагування даних медіа' className='modal-nested' backdropClassName='modal-backdrop-nested'>
            {state.data ? (
                <MediaEditFormBody data={state.data} />
            ) : (
                <BouncingLoader />
            )}
        </Modal>
    );
}

export default MediaEditModal
