import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { OvaEngineAdmin, OvaEngineAppConfig } from "@ova-engine/engine-admin"
import {EditorConfig} from "@ova-studio/react-block-editor";

import "@ova-engine/engine-admin/css/admin.css"

const config : OvaEngineAppConfig = {
    sentryDsn: process.env.SENTRY_DSN || undefined,
    appRelease: process.env.APP_RELEASE_VERSION || 'development',
}

const editorConfig : Partial<EditorConfig> = {
    table: false,
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <OvaEngineAdmin config={config} editorConfig={editorConfig} />,
)
