import {Media} from "../types/Media";
import MediaLibraryService from "./MediaLibraryService";
import API, {getApiErrorMessage} from "@ova-studio/api-helper";
import {SimpleCallback} from "@ova-studio/react-hyper-admin";
import {MediaType} from "../types/MediaType";

type OnMediaUpdateListener = (media: Media) => void;
type OnMediaDeleteListener = (mediaId: Media['id']) => void;

export default class MediaManager {
    private readonly _service: MediaLibraryService;

    private _onMediaUpdateListeners: OnMediaUpdateListener[] = [];
    private _onMediaDeleteListeners: OnMediaDeleteListener[] = [];

    constructor(service: MediaLibraryService) {
        this._service = service;
    }

    public onMediaUpdate(listener: OnMediaUpdateListener) : SimpleCallback {
        this._onMediaUpdateListeners.push(listener);

        return () => {
            this._onMediaUpdateListeners = this._onMediaUpdateListeners.filter(l => l !== listener);
        };
    }

    public onMediaDelete(listener: OnMediaDeleteListener) : SimpleCallback {
        this._onMediaDeleteListeners.push(listener);

        return () => {
            this._onMediaDeleteListeners = this._onMediaDeleteListeners.filter(l => l !== listener);
        };
    }

    public async openMediaEdit(id: Media['id']) {
        if (!this._service.states) {
            throw new Error('MediaLibraryService states not set');
        }

        this._service.states.mediaEditModal.setData(null);
        this._service.states.mediaEditModal.open();

        try {
            const media = await this._service.loadMedia(id);
            this._service.states.mediaEditModal.setData({
                id: media.id,
                folder_id: media.folder_id,
                meta_data: {
                    name: media.meta_data?.name ?? '',
                    description: media.meta_data?.description ?? '',
                }
            });
        } catch (e) {
            this._service.states.mediaEditModal.close();
            this._service.app.toasts.createToast({
                title: 'Помилка завантаження даних',
                body: getApiErrorMessage(e),
                variant: 'danger',
            });
        }
    }

    public saveMediaImageEdit(id: Media['id'], filename: Media['filename'], imageCanvas: HTMLCanvasElement, state: object) : Promise<void> {
        const endpoint = this._service.getEndpoint(`media/${id}/edit`);

        return new Promise<void>((resolve, reject) => {
            imageCanvas.toBlob(async (blob) => {
                if (!blob) {
                    reject(new Error('Failed to save image'));
                    return;
                }

                const file = new File([blob], `edited-${filename}.jpg`);

                try {
                    const response = await API.postWithFile(endpoint, { image: file, state: JSON.stringify(state) });
                    resolve();

                    this._service.upload.addMedia(response.data as Media);
                } catch (e) {
                    reject(new Error(getApiErrorMessage(e)));
                }
            }, 'image/jpeg', 0.9);
        });
    }

    public async openMediaImageEdit(id: Media['id']) {
        if (!this._service.states) {
            throw new Error('MediaLibraryService states not set');
        }

        this._service.states.mediaImageEditorModal.setData(null);
        this._service.states.mediaImageEditorModal.open();

        try {
            const media = await this._service.loadMedia(id);

            if (media.type !== MediaType.Image) {
                this._service.states.mediaImageEditorModal.close();
                this._service.app.toasts.createToast({
                    title: 'Помилка',
                    body: 'Можна редагувати тільки зображення',
                    variant: 'danger',
                });
                return;
            }

            this._service.states.mediaImageEditorModal.setData(media);
        } catch (e) {
            this._service.states.mediaEditModal.close();
            this._service.app.toasts.createToast({
                title: 'Помилка завантаження даних',
                body: getApiErrorMessage(e),
                variant: 'danger',
            });
        }
    }

    public async handleEditFormSubmit(id: Media['id'], data: Record<string, any>) {
        const endpoint = this._service.getEndpoint(`media/${id}`);
        const { data: newData } = await API.put(endpoint, data);

        this._onMediaUpdateListeners.forEach(l => l(newData as Media));

        this._service.states?.mediaEditModal.close();
    }

    public async openMediaDelete(id: Media['id']) {
        if (!this._service.states) {
            throw new Error('MediaLibraryService states not set');
        }

        this._service.states.deleteModal.setData(null);
        this._service.states.deleteModal.open();

        try {
            const media = await this._service.loadMedia(id);
            this._service.states.deleteModal.setData({
                text: `Видалити медіа ${media.filename}?`,
                onConfirm: () => this.handleMediaDelete(id),
            });
        } catch (e) {
            this._service.states.deleteModal.close();
            this._service.app.toasts.createToast({
                title: 'Помилка завантаження даних',
                body: getApiErrorMessage(e),
                variant: 'danger',
            });
        }
    }

    public async handleMediaDelete(id: Media['id']) {
        const endpoint = this._service.getEndpoint(`media/${id}`);
        await API.delete(endpoint);

        this._onMediaDeleteListeners.forEach(l => l(id));
    }

}
