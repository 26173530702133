import * as React from 'react';
import {
    connectMenu,
    ContextMenu,
    ContextMenuTrigger,
    ContextMenuTriggerProps,
    MenuItem
} from "@firefox-devtools/react-contextmenu";
import {Media} from "../../types/Media";
import {useMediaLibraryService} from "../../services/MediaLibraryService";
import {MediaType} from "../../types/MediaType";

export const menuType: string = 'media-context';

type MediaContextMenuProps = {
    id: string,
    trigger: {
        media: Media,
    },
}

const MediaContextMenu = ({ id, trigger }: MediaContextMenuProps) => {

    const mediaService = useMediaLibraryService().mediaManager;

    return (
        <ContextMenu id={id}>
            <MenuItem onClick={() => mediaService.openMediaEdit(trigger.media.id)}>Редагувати дані</MenuItem>
            {trigger?.media?.type === MediaType.Image && <MenuItem onClick={() => mediaService.openMediaImageEdit(trigger.media.id)}>Редагувати зображення</MenuItem>}
            <MenuItem onClick={() => mediaService.openMediaDelete(trigger.media.id)}>Видалити</MenuItem>
        </ContextMenu>
    );
}

export const MediaConnectedContextMenu = connectMenu(menuType)(MediaContextMenu);

type MediaContextMenuTriggerProps = {
    as?: ContextMenuTriggerProps['renderTag'],
    attributes?: ContextMenuTriggerProps['attributes'],
    media: Media,
    triggerOnLeftClick?: boolean,
    children: React.ReactNode,
}

export const MediaContextMenuTrigger = ({ as, attributes, media, triggerOnLeftClick, children } : MediaContextMenuTriggerProps) => {
    return (
        <ContextMenuTrigger id={menuType} media={media} renderTag={as} attributes={attributes} collect={(data) => data} triggerOnLeftClick={triggerOnLeftClick}>
            {children}
        </ContextMenuTrigger>
    );
}
